import { DashboardMultiGroupRes } from '../../api';
import { DashboardCategory } from '../../category';

type ChartValueArr<T> = Record<number, T>;

export interface DashboardMultiGroupByCategory {
  category: DashboardCategory;
  count: ChartValueArr<number>;
  value: ChartValueArr<number>;
}

export interface DashboardMultiGroup {
  byCategory: DashboardMultiGroupByCategory[];
  total: {
    count: ChartValueArr<number>;
    value: ChartValueArr<number>;
  };
}

export function toDashboardMultiGroup(
  { total, categoryList }: DashboardMultiGroupRes,
  categoryMap?: (category: DashboardCategory) => DashboardCategory,
): DashboardMultiGroup {
  const byCategory: DashboardMultiGroup['byCategory'] = [];
  const categoryMapFn = categoryMap || ((c) => c);
  for (let i = 0; i < categoryList.length; i++) {
    byCategory.push({
      category: categoryMapFn(categoryList[i].category),
      count: toChartValueArr(categoryList[i].count),
      value: toChartValueArr(categoryList[i].value),
    });
  }
  return {
    byCategory,
    total: {
      count: total.count,
      value: total.value,
    },
  };
}

function toChartValueArr<T>(arr: T[]): ChartValueArr<T> {
  const res: ChartValueArr<T> = {};
  for (let i = 0; i < arr.length; i++) {
    res[i] = arr[i];
  }
  return res;
}
