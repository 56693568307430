import { DashboardBugInfo } from './dashboard-bug-info';
import { DashboardAccountInfo } from './dashboard-account-info';
import {
  AccountMini,
  eImpactLevel,
  IAccountPriority,
  WithAccount,
} from '@pwiz/entity/ts';
import { DashboardItemRisk } from '../../dashboard-item-risk';
import { calcItemRisk, getItemAccountImpact } from '@pwiz/item/ts';
import { isEmptyAccount } from '@pwiz/account/ts';
import { DashboardBugsByAccountBreakdown } from '../../api';
import { QueryParams } from '@pwiz/infra/ts';

export interface DashboardOutstandingRiskInfo {
  info: DashboardBugInfo;
  accountList: DashboardAccountInfo<{
    affectedBugs: number;
    maxRisk: number;
    link: { url: string[]; queryParams: QueryParams };
  }>[];
}

export function getDashboardOutstandingRiskAccountInfo(
  account: AccountMini,
  itemList: DashboardItemRisk[],
) {
  if (isEmptyAccount(account)) {
    return {
      affectedBugs: itemList.length,
      maxRisk: 0,
    };
  }
  const { maxImpact, impactItem } = findMaxImpact(account, itemList);
  if (!impactItem) {
    throw new Error('No impact item found');
  }
  if (!maxImpact) {
    throw new Error('No max impact found');
  }
  return {
    affectedBugs: itemList.length,
    maxRisk: calcItemRisk(impactItem, [maxImpact]),
  };
}

export function findMaxImpact(
  account: AccountMini,
  itemList: DashboardItemRisk[],
) {
  let maxImpact: WithAccount<IAccountPriority> | null = null;
  let impactItem: DashboardItemRisk | null = null;
  for (const item of itemList) {
    const accountPriority = getItemAccountImpact(
      item,
      account.id,
    ) as WithAccount<IAccountPriority>;
    if (!accountPriority) {
      continue;
    }
    if (!maxImpact || accountPriority.priorityLevel > maxImpact.priorityLevel) {
      maxImpact = accountPriority;
      impactItem = item;
    }
    if (maxImpact.priorityLevel === eImpactLevel.deal_breaker) {
      break;
    }
  }
  return { maxImpact, impactItem };
}

export function toDashboardOutstandingRiskInfo(
  { info, accountList }: DashboardBugsByAccountBreakdown,
  url: string,
  params: QueryParams,
): DashboardOutstandingRiskInfo {
  return {
    info,
    accountList: accountList.map((account) => {
      return {
        ...account,
        link: {
          url: ['', url],
          queryParams: {
            ...params,
            account: account.account.id,
          },
        },
      };
    }),
  };
}
