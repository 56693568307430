import { DashboardRemovedRiskRes } from './api';
import {
  DashboardBugInfo,
  DashboardDonutChartCurrency,
  DashboardDonutChartCurrencyWrapper,
} from './ui';

export type DashboardBugRemovedRisk = Omit<
  DashboardRemovedRiskRes,
  'itemCategoryList'
> & {
  resolvedPercent: number;
  chart: DashboardDonutChartCurrencyWrapper;
  info: DashboardBugInfo;
};

export function mapDashboardRemovedRiskToDashboardDonut({
  itemCategoryList,
  ...res
}: DashboardRemovedRiskRes): DashboardBugRemovedRisk {
  const chartDataList = Object.values(itemCategoryList).reduce<
    DashboardDonutChartCurrency[]
  >(
    (list, value) => [
      ...list,
      {
        label: value.category.shortName,
        fullLabel: value.category.name,
        value: { 0: value.risk },
        count: { 0: value.count },
        category: value.category,
      },
    ],
    [],
  );
  return {
    ...res,
    resolvedPercent: res.doneItemCount / res.totalItemCount,
    info: {
      risk: res.totalRisk,
      count: res.totalItemCount,
      totalRelatedArr: res.totalArr,
      cost: res.cost,
      relatedAccounts: res.relatedCustomers,
    },
    chart: {
      data: chartDataList,
      totalRisk: [res.totalRisk],
      totalCount: [res.itemCount],
    },
  };
}
