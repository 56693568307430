import { DashboardBugInfo } from '../ui';

export interface DashboardInfoRes<T> {
  info: DashboardBugInfo;
  chart: T;
}

export function mapDashboardInfoRes<T, R>(
  { info, chart }: DashboardInfoRes<T>,
  mapper: (value: T) => R,
) {
  return {
    info,
    chart: mapper(chart),
  };
}
