import { ToFormGroup } from '@pwiz/infra/form';
import { FormGroup } from '@angular/forms';
import {
  PwReplaceProperty,
  PwTimelineFilter,
  PwTimelineFilterForm,
  PwTimelineFilterQueryParams,
  queryParamsToString,
  queryParamToBoolean,
  toPwTimelineFilterQueryParams,
  toPwTimelineQueryParams,
  ToQueryParam,
} from '@pwiz/infra/ts';

export interface DashboardPageFilter {
  divisionId?: string | null;
  active?: boolean | null;
  timeline: PwTimelineFilter;
}

export type DashboardPageFilterForm = PwReplaceProperty<
  ToFormGroup<DashboardPageFilter>,
  'timeline',
  FormGroup<PwTimelineFilterForm>
>;

export type DashboardPageFilterFormContainer = {
  filter: FormGroup<DashboardPageFilterForm>;
};

export type DashboardPageFilterQueryParams = PwTimelineFilterQueryParams &
  ToQueryParam<Omit<DashboardPageFilter, 'timeline'>>;

export function toDashboardPageFilter({
  divisionId,
  active,
  ...timeline
}: DashboardPageFilterQueryParams) {
  return {
    divisionId: queryParamsToString(divisionId),
    active: queryParamToBoolean(active),
    timeline: toPwTimelineFilterQueryParams(timeline),
  };
}

export function toDashboardPageFilterQueryParams({
  divisionId,
  active,
  timeline,
}: DashboardPageFilter) {
  return {
    ...(divisionId ? { divisionId } : {}),
    ...(active ? { active } : {}),
    ...toPwTimelineQueryParams(timeline),
  };
}
