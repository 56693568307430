import { DashboardPageFilter } from '../../type';
import { getTimelineFilterDates } from '@pwiz/infra/ts';
import { getCurrentQuarterStartDate } from '@pwiz/settings/ts';
import { toItemFilterParams } from '@pwiz/item/ts';
import { ISettings } from '@pwiz/entity/ts';

export function convertDashboardParamsToItemFilter(
  filter: DashboardPageFilter,
  settings: ISettings | null,
) {
  if (!settings) {
    return {};
  }
  const board: string[] = [];
  if (filter.divisionId) {
    board.push(filter.divisionId);
  }
  const { from, to } = getTimelineFilterDates(
    filter.timeline,
    getCurrentQuarterStartDate(settings),
  );
  return toItemFilterParams({
    board,
    fromDate: from,
    toDate: to,
  });
}
