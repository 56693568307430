import { PwReplaceProperty, TimeUtils } from '@pwiz/infra/ts';
import { DashboardBugBurndownRes } from '../../api';

export type DashboardBugBurndownChart = PwReplaceProperty<
  DashboardBugBurndownRes,
  'bugBurndownByMonth',
  {
    month: string;
    closed: { count: number | null };
    opened: { count: number | null };
    diff: { count: number | null };
  }[]
>;

export function toDashboardBugBurndownChart(
  res: DashboardBugBurndownRes,
): DashboardBugBurndownChart {
  return {
    ...res,
    bugBurndownByMonth: Object.entries(res.bugBurndownByMonth).map(
      ([key, { closed, opened }]) => {
        const closedCount = closed.count || null;
        const openedCount = opened.count || null;
        return {
          closed: { count: closedCount },
          opened: { count: openedCount },
          diff: {
            count:
              closedCount || openedCount ? opened.count - closed.count : null,
          },
          month: TimeUtils.getMonthName(
            TimeUtils.createDateFromToday({ month: Number(key) }),
          ),
        };
      },
    ),
  };
}
