import { DashboardAccountTierRes } from '../../api';
import { PwReplaceProperty } from '@pwiz/infra/ts';
import { getAccountTierDisplayName } from '@pwiz/account/ts';

export type DashboardAccountTierChartData = PwReplaceProperty<
  DashboardAccountTierRes['itemByTier'][number],
  'tier',
  string
>[];

export function toDashboardAccountTierChartData(
  res: DashboardAccountTierRes,
): DashboardAccountTierChartData {
  return res.itemByTier.map(({ tier, ...items }) => ({
    ...items,
    tier: getAccountTierDisplayName({ tier }),
  }));
}
