import { DashboardActiveDoneRiskRes, DashboardItemCountRes } from '../../api';
import { DashboardCategory } from '../../category';

export interface DashboardDonutChartCurrency {
  label: string;
  fullLabel: string;
  value: Record<number, number>;
  count: Record<number, number>;
  category: DashboardCategory;
}

export interface DashboardDonutChartCurrencyWrapper {
  totalRisk: number[];
  totalCount: number[];
  data: DashboardDonutChartCurrency[];
}

export function convertDashboardItemCountResToDashboardDonutChartCurrencyWrapper(
  res: DashboardItemCountRes,
): DashboardDonutChartCurrencyWrapper {
  return {
    totalRisk: [res.totalRisk],
    totalCount: [res.itemCount],
    data: res.itemCategoryList.map(
      ({ category, count, risk }) =>
        <DashboardDonutChartCurrency>{
          count: { 0: count },
          value: { 0: risk },
          label: category.shortName,
          fullLabel: category.name,
          category,
        },
    ),
  };
}

export function convertDashboardActiveDoneItemResToDonutChartCurrencyWrapper(
  res: DashboardActiveDoneRiskRes,
): DashboardDonutChartCurrencyWrapper {
  return {
    totalRisk: [res.done.risk, res.active.risk],
    totalCount: [res.done.count, res.active.count],
    data: res.itemCategoryList.map(
      ({ category, active, done }) =>
        <DashboardDonutChartCurrency>{
          count: { 0: done.count, 1: active.count },
          value: { 0: done.risk, 1: active.risk },
          label: category.shortName,
          fullLabel: category.name,
          category,
        },
    ),
  };
}
