import {
  DEFAULT_ITEM_FILTER,
  isEqualItemFilter,
  ItemFilter,
  ItemFilterForm,
  ItemQueryWhere,
} from '@pwiz/item/ts';
import {
  deepEqual,
  mapDateFilterToDateQuery,
  PwReplaceProperties,
  PwReplacePropertiesV2,
  PwReplaceProperty,
  queryParamFromStringOption,
  queryParamsFromJson,
  queryParamsToJson,
  queryParamsToString,
  ToQueryParam,
} from '@pwiz/infra/ts';
import { ToFormGroup } from '@pwiz/infra/form';
import { FormGroup } from '@angular/forms';
import {
  CUSTOM_CHART_ENTITY_LIST,
  CustomChartEntity,
  CustomChartItemData,
  CustomChartVisualForm,
  CustomDashboardConfig,
  DASHBOARD_CHART_OPTIONS,
  DASHBOARD_ITEM_GROUP_BY_OPTIONS,
  DASHBOARD_ITEM_VALUE,
} from '@pwiz/entity/ts';

export type CustomChartDto = PwReplacePropertiesV2<
  CustomDashboardConfig,
  'data',
  {
    data: PwReplaceProperty<CustomChartItemData, 'filter', ItemFilter>;
    selectedFilter: string | null;
  }
>;

export type CustomChartDataForm = PwReplaceProperties<
  ToFormGroup<CustomChartItemData>,
  'filter',
  {
    filter: FormGroup<ItemFilterForm>;
  }
>;

export type CustomChartForm = PwReplacePropertiesV2<
  ToFormGroup<CustomChartDto>,
  'data' | 'chartType',
  {
    data: FormGroup<CustomChartDataForm>;
    chartType: FormGroup<CustomChartVisualForm>;
  }
>;

export function mapCustomChartDtoToItemWhere({
  filter: {
    type,
    status,
    closedDate,
    dueDate,
    createdDate,
    team,
    priority,
    division,
    impact,
    keyResult,
    strategicInitiative,
    objective,
  },
}: CustomChartDto['data']): ItemQueryWhere {
  return {
    type,
    status,
    priority,
    keyResult,
    strategicInitiative,
    issueBoard: division,
    issueTeam: team,
    issueClosedAt: closedDate ? mapDateFilterToDateQuery(closedDate) : null,
    issueCreatedAt: createdDate ? mapDateFilterToDateQuery(createdDate) : null,
    deliverBy: dueDate ? mapDateFilterToDateQuery(dueDate) : null,
    businessGoal: objective,
    impactLevel: impact,
  };
}

export type CustomChartDtoQueryParams = ToQueryParam<{
  entity: string;
  data: string;
  groupBy: string;
  chart: string;
  chartValue: string;
  selectedFilter: string | null;
}>;

export function toCustomChartDto({
  entity,
  chartValue,
  chart,
  groupBy,
  data,
  selectedFilter,
}: CustomChartDtoQueryParams): CustomChartDto {
  return {
    selectedFilter: queryParamsToString(selectedFilter),
    data: {
      entity: queryParamFromStringOption(
        CUSTOM_CHART_ENTITY_LIST,
        entity,
        'item',
      ),
      filter: queryParamsFromJson<ItemFilter>(data, DEFAULT_ITEM_FILTER),
    },
    groupBy: queryParamFromStringOption(
      DASHBOARD_ITEM_GROUP_BY_OPTIONS,
      groupBy,
      'division',
    ),
    chartType: {
      chartType: queryParamFromStringOption(
        DASHBOARD_CHART_OPTIONS,
        chart,
        'donut',
      ),
      measure: queryParamFromStringOption(
        DASHBOARD_ITEM_VALUE,
        chartValue,
        'risk',
      ),
    },
  };
}

export function toCustomChartQueryParams({
  data,
  chartType,
  groupBy,
}: CustomChartDto): CustomChartDtoQueryParams {
  return {
    data: queryParamsToJson(data.filter),
    entity: data.entity,
    groupBy,
    chart: chartType.chartType,
    chartValue: chartType.measure,
  };
}

export function isEqualCustomChartConfig(
  { data: dataA, ...a }: CustomChartDto,
  { data: dataB, ...b }: CustomChartDto,
) {
  if (!deepEqual(a, b)) {
    return false;
  }
  if (dataA.entity !== dataB.entity) {
    return false;
  }
  const map: Partial<Record<CustomChartEntity, (a: any, b: any) => boolean>> = {
    item: isEqualItemFilter,
  };
  const compare = map[dataB.entity];
  return compare ? compare(dataA.filter, dataB.filter) : true;
}
