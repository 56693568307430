import { DashboardCategory } from '../../type';
import { AccountSatisfactionLevel } from '@pwiz/account/ts';

export function getSatisfactionLevelColor(
  category: DashboardCategory,
  colorMap?: Record<AccountSatisfactionLevel, string>,
) {
  const map: Record<AccountSatisfactionLevel, string> = colorMap || {
    high: 'green',
    medium: 'yellow',
    low: 'red',
  };
  return <DashboardCategory>{
    ...category,
    color: map[<AccountSatisfactionLevel>category.id],
  };
}
